/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { persistor, store } from '../store';
import theme from '../style/variables';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from '../routes/routes';
import { LoadingGlobal } from '../pages/CRs_T12_2022_HomePage/components/LoadingGlobal';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
    <LoadingGlobal />
  </Provider>
);

export default App;