/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Storage from "../utils/storega";

const PrivateRoute = ({
  component: Component,
  condition = () => {},
  redirect,
  ...rest
}) => {
    const getSearch = new URLSearchParams(window.location.search);
    let schoolID = (Storage.get('SCHOOL_ID') === null) ? getSearch.get('schoolId') : Storage.get('SCHOOL_ID');
    if (schoolID === null) {
      schoolID= '0001';
    }
  // if (!Storage.has("USER_ACCESS_TOKEN")) {
    const clearCacheBrowser = () => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      // Clear all cache of browser
      // window.caches.keys().then((names) => {
      //   // eslint-disable-next-line no-restricted-syntax, prefer-const
      //   for (let name of names) window.caches.delete(name);
      // }
      // );
    }  
    if(!Storage.has("USER_ACCESS_TOKEN")){
      // Check if user is not login, clear all cache of browser, condition is path is not /sign-in
      if (window.location.pathname !== '/sign-in' && window.location.pathname !== '/sign-in-admin') {
        clearCacheBrowser();
        window.location.href = '/sign-in';
      }
    }
  //   // redirect += (Storage.get('SCHOOL_ID') === null || undefined) ? '' : `?schoolId=${  Storage.get('SCHOOL_ID')}`;
  //   redirect += (schoolID !== (null || undefined)) ? `?schoolId=${schoolID}` : `?schoolId=${  Storage.get('SCHOOL_ID')}`;

  // }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (condition()) return <Component {...props} />;
        return null
        // return <Redirect to={redirect} />;
      }}
    />
  );
}



export default PrivateRoute;